import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { SharedModule } from 'src/app/modules/shared/shared.module';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      NgxPermissionsModule.forRoot(),
      SharedModule.forRoot(),
      BrowserAnimationsModule
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes)]
};
